

























import {Component, Vue} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class TimeTrackingLegendComponent extends Vue {
private isOpen: boolean = false;

}
